<template>
    <div class="">
        <div class="bg-white p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.client">
            <Loader />
        </div>
        <div v-else class="financialComponent">
            <FormulateForm>
                <div class="flex flex-col gap-6">
                    <!-- Financial Details -->
                    <div>
                        <h1 class="text-gray-700 font-semibold text-lg bg-primary-300 HeadingPart">Financial Details</h1>
                        <div class="bg-white p-6 financialDetail">
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full financialHeading" :min='0' label="Tax number (eg. VAT, GSTIN etc.)" placeholder="Tax number (eg. VAT, GSTIN etc.)" type="text" v-model="financials.financial_details.tax_number" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <div>
                                    <h2 class="mb-2 mt-1 financialHeading">Currency</h2>
                                    <Dropdown
                                        placeholder="Currency"
                                        :options="currencyOptions"
                                        searchable
                                        :colors="{
                                            text: '#282828',
                                            bg: 'white',
                                            svg: '#282828',
                                            border: '#bcbcbc',
                                        }"
                                        v-model="financials.financial_details.currency"
                                        @input="(value) => updateCurrency(value)"
                                        :config="{ label: 'currency_code', trackBy: 'currency_code' }"
                                        maxWidth="100%"
                                        minWidth="100%"
                                        width="102px"
                                        :loading="loadingState.currency"
                                    />
                                </div>
                                <div class="grid gap-2">
                                    <h1 class="text-black financialHeading">Payment Terms</h1>
                                    <div class="flex gap-8">
                                    <label class="label cursor-pointer gap-2">
                                        <input type="radio" name="payment-radio" value='0' v-model="financials.financial_details.payment_terms" />
                                        <span class="label-text">Online Payment</span> 
                                    </label>
                                    
                                    <label class="label cursor-pointer gap-2">
                                        <input type="radio" name="payment-radio" value='1' v-model="financials.financial_details.payment_terms"/>
                                        <span class="label-text">Offline Payment</span> 
                                    </label>
                                </div>
                                </div>
    
                                <div class="grid gap-2">
                            <h1 class="financialHeading">Order Approval</h1>
                            <div class="flex gap-8">
                                <label class="label cursor-pointer gap-2">
                                    <input type="radio" name="order-approval" value="0" v-model="financials.financial_details.order_approval"/>
                                    <span class="label-text">Auto</span> 
                                </label>
    
                                <label class="label cursor-pointer gap-2">
                                    <input type="radio" name="order-approval" value="1" v-model="financials.financial_details.order_approval"/>
                                    <span class="label-text">Manual</span> 
                                </label>
                            </div>
                                </div>
                                <div class="grid gap-2">
                                <h1 class="financialHeading">
                                    Corporate Discount
                                </h1>
                                <label class="input-group">
                                    <span class="percentIcon">%</span>
                                    <input type="text" v-model="financials.financial_details.discount"  placeholder="Discount" class="input input-bordered input-sm customDiscountInput" @keypress="isNumber" @input="handleDiscount($event)" />
                                </label>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!-- Purchase Details -->
                    <!-- <div>
                        <h1 class="text-gray-700 font-semibold text-lg mb-3">Billing Address</h1>
                        <div class="bg-white p-6 rounded-lg">
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Purchase Account" placeholder="Purchase account" type="text" v-model="financials.purchase_details.purchase_account" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput class="w-full" label="Billing due date" placeholder="Billing due date" type="date" v-model="financials.purchase_details.billing_due_date" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                            <div class="grid grid-cols-2 gap-6 mb-6">
                                <div>
                                    <h2 class="mb-2 mt-1">Currency</h2>
                                    <Dropdown
                                        placeholder="Amounts are"
                                        :options="amountsAreOptions"
                                        :colors="{
                                            text: '#282828',
                                            bg: 'white',
                                            svg: '#282828',
                                            border: '#bcbcbc',
                                        }"
                                        v-model="financials.purchase_details.amounts_are"
                                        maxWidth="100%"
                                        minWidth="100%"
                                        width="102px"
                                    />
                                </div>
    
                                <div>
                                    <h2 class="mb-2 mt-1">Currency</h2>
                                    <Dropdown
                                        placeholder="purchase VAT"
                                        :options="purchaseVatOptions"
                                        :colors="{
                                            text: '#282828',
                                            bg: 'white',
                                            svg: '#282828',
                                            border: '#bcbcbc',
                                        }"
                                        v-model="financials.purchase_details.purchase_vat"
                                        maxWidth="100%"
                                        minWidth="100%"
                                        width="102px"
                                    />
                                </div>
                            </div>
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Type" placeholder="Type" type="text" v-model="financials.purchase_details.type" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput class="w-full" label="Salesman" placeholder="Salesman" type="text" v-model="financials.purchase_details.salesman" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                        </div>
                    </div> -->
                </div>
            </FormulateForm>
        </div>
        <div class="flex mt-4 w-full justify-between bg-white p-4 buttonPanel">
            <Button text="Back" type="outline" @click="$emit('handleBackSection')" />
            <Button :text="$route.params.tenant_id?'Update & Next':'Save & Next'" :loader="isFormSubmit" type="primary" @click="handleSubmit" />
        </div>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import Dropdown from "@shared/components/dropdown-base";
import { fetchClientInfo, getCurrencyList } from "../services";
import Loader from "@shared/loader";
import {getTenantDetails} from "../../edit-tenant/services"
import { isNumber } from "@/plugins/functions";

export default {
    name: "client-financial",
    components: {
        Button,
        Dropdown,
        Loader,
    },
    props: {
        loadingStates:{
            type:Object,
            default: () => {}
        }
    },
    data() {
        return {
            financials: {
                financial_details: {
                    tax_number: "",
                    currency: "",
                    key: "financial_details",
                    payment_terms: null,
                    order_approval: null,
                    discount: null
                },
                // purchase_details: {
                //     purchase_account: "",
                //     billing_due_date: "",
                //     amounts_are: "",
                //     purchase_vat: "",
                //     type: "",
                //     salesman: "",
                // },
            },
            currencyOptions: [],
            amountsAreOptions: ["Tax Inclusive", "Tax Exclusive", "No Tax"],
            purchaseVatOptions: ["12.5% (VAT on expenses)", "20% (VAT on expenses)", "20% (VAT on income)", "EC acquisitions (205)"],
            loadingState: {
                currency: false,
                client: false,
            },
            clientId: null,
        };
    },
    computed:{
        isFormSubmit(){
            return this.loadingStates.formSubmit
        }
    },
    async mounted() {
         if(this.$route.params?.tenant_id) {
            this.loadingState.client = true
            const {data} = await getTenantDetails(this.$route.params.tenant_id)
            await this.getCurrencyLists(data);
            this.loadingState.client = false
        }
        else {
            await this.getCurrencyLists()
        }
    },
    methods: {
        isNumber,
        async getCurrencyLists(data) {
            if (data) {
            let {tax_number, currency_id, payment_terms, order_approval, discount} = data
            this.loadingState.currency = true;
            const currencyData = await getCurrencyList();
            this.currencyOptions = currencyData.data
            const currencyCode = this.currencyOptions.find((res)=> res.id == currency_id)
             this.financials.financial_details = {
                tax_number,
                payment_terms,
                order_approval,
                discount,
                validDiscount: discount
                // currency: null
            }
            
            this.financials.financial_details.currency = currencyCode
            this.loadingState.currency = false;
            }
            else {
                const currencyData = await getCurrencyList();
                this.currencyOptions = currencyData.data
                const currencyCode = this.currencyOptions.find((res)=> res.base_currency == true)
                this.financials.financial_details.currency = currencyCode 
            }
        },
        updateCurrency(value) {
            this.financials.financial_details.currency = {
                id: value.id,
                currency_code: value.currency_code
            }
        },
        async getClientDetails() {
            this.loadingState.client = true;
            this.clientId = this.$route.params.clientId;
            if (this.clientId) {
                let response = await fetchClientInfo(this.clientId);
                let { tax_number, currency } = response;
                this.financials = { ...this.financials, financial_details: { tax_number, currency } };
            }
            this.loadingState.client = false;
        },
        handleSubmit(hasErrors) {
            if (hasErrors) {
                this.$toast.error(`Please fill all the required fields`);
            } else {
                this.financials.financial_details.payment_terms = parseInt(this.financials.financial_details.payment_terms)
                this.financials.financial_details.order_approval = parseInt(this.financials.financial_details.order_approval)
                this.financials.financial_details.currency_id = this.financials.financial_details.currency?.id
                this.$emit("submitForm", this.financials.financial_details);
            }
        },
        handleDiscount(event) {
            if (event.target.value > 100) {
                this.financials.financial_details.discount = this.financials.financial_details.validDiscount
            }
            if (event.target.value <= 100) {
                this.financials.financial_details.validDiscount = this.financials.financial_details.discount
            }
        }
    },
};
</script>

<style lang="scss" scoped>

    .financialComponent {
        // min-height: 75vh;
    }
    .HeadingPart {
        // background-color: #99CBFF;
        padding: 10px;
        border-radius: 10px 10px 0px 0px;
        color: #000;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .financialDetail {
        border-radius: 0px 0px 10px 10px;
    }

    .financialHeading {
        color: #282828;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .percentIcon {
        background: #D6E9FF;
        border: 1px solid #DDD;;
    }

    .customDiscountInput {
        border-left: none;
    }

    .buttonPanel {
        position: absolute;
        left: 0;
        // bottom: 0;
    }
</style>
