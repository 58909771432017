<template>
    <div class="flex flex-col gap-2 flex-1">
        <div class="flex justify-end">
            <Button @click="handleShowModal" text="Add User" type="primary" />
        </div>
        <vue-good-table class="flex-1" :columns="columns" :rows="rows"> </vue-good-table>
        <AddUserModal ref="adduserModal" @handleSubmit="addUser($event)" :roles="roles" :groups="groups" :loadingState="loadingState" :fixed-header="false" />
    </div>
</template>

<script>
import Button from "@shared/components/button";
import AddUserModal from "@shared/components/addUserModal";
import axios from "@/axios";
// import Dropdown from "@shared/components/dropdown-base";

export default {
    name: "users",
    components: {
        Button,
        // Dropdown,
        AddUserModal,
    },
    props: {},
    data() {
        return {
            tenant_id: null,
            columns: [
                {
                    label: "User Name",
                    field: "title",
                },
                {
                    label: "User decription",
                    field: "description",
                },
            ],
            rows: [],
            roles: [],
            groups: [],
            loadingState: {
                roles: false,
                groups: false,
            },
        };
    },
    async mounted(){
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.tenant_id = this.$store.getters.getTenantId;
    },
    methods: {
        addUser(event) {
            console.log(event, "add User");
        },
        async fetchRoles() {
            this.loadingState.roles = true;
            try {
                let { data } = await axios.get(`role/tenant/${this.tenant_id}`);
                this.roles = data;
            } catch (error) {
                console.log(error, ">>>>error");
            }
            this.loadingState.roles = false;
        },
        async fetchGroups() {
            this.loadingState.groups = true;
            try {
                let { data } = await axios.get(`group/tenant/${this.tenant_id}`);
                this.groups = data;
            } catch (error) {
                console.log(error, ">>>>error");
            }
            this.loadingState.groups = false;
        },
        async handleShowModal() {
            this.$refs["adduserModal"].showUserModal();
            await this.fetchRoles();
            await this.fetchGroups();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
