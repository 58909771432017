<template>
    <div class="flex-1 flex overflow-y-auto p-4 -m-4">
        <tabs class="overflow-y-auto" @select="selectTab" :selected="tabs.selected" :tabs="tabs.list">
            <template class="overflow-y-auto" #content-tenantdomain> 
                <TenantDomain/>
            </template>
            <template #content-emaildomain>
                <EmailDomain/>
            </template>
        </tabs>
    </div>
</template>

<script>
import tabs from "@/components/dashboard-components/tabs";
import TenantDomain from "./tenant-domain"
import EmailDomain from "./email-domain"

export default {
    name: "field-tab",
    components: {
        tabs,
        TenantDomain,
        EmailDomain,
    },
    props: {},
    data() {
        return {
            tabs: {
                selected: "tenantdomain",
                list: [
                    {
                        id: "tenantdomain",
                        label: "Tenant Domain",
                    },
                    {
                        id: "emaildomain",
                        label: "Email Domain",
                    },
                ],
            },
        };
    },
    computed: {},
    mounted() {
        this.tabs.selected = this.$route?.query?.tab || "tenantdomain";
    },
    methods: {
        selectTab(id) {
            this.tabs.selected = id;
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
