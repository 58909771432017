<template>
    <div class="flex flex-1 overflow-y-auto flex-col space-y-6 max-h-full max-w-full overflow-x-hidden">
        <div class="grid flex-1 grid-cols-2 space-x-6 overflow-y-auto max-w-full">
            <div class="overflow-y-auto bg-white p-4 rounded-md max-w-full" style="min-height: 300px">
                <div v-if="loadingState && loadingState.packages" class="flex justify-center items-center min-h-full">
                    <Loader />
                </div>
                <ListView :disableCheckbox="loadingState.assigningpackages" title="Assigned Packages" @rowClick="handlePackageClick($event)" v-else :listItems="filterdPackages" :showSearch="true" class="h-full overflow-y-auto" @handleFilterResult="handleFilterResult($event, 'packages')" valueKey="package_name" />
            </div>
            <div class="overflow-y-auto bg-white p-4 rounded-md max-w-full" style="min-height: 300px">
                <div v-if="loadingState && loadingState.checks" class="flex justify-center items-center min-h-full">
                    <Loader />
                </div>
                <ListView title="Assigned Checks" :disableCheckbox="loadingState.assigningchecks" v-else :listItems="filterdChecks" @rowClick="handleCheckClick($event)" :showSearch="true" class="h-full overflow-y-auto" @handleFilterResult="handleFilterResult($event, 'checks')" />
            </div>
        </div>
        <!-- <div class="flex justify-between">
            <Button text="cancel" type="outline" />
            <Button text="Save" type="primary" />
        </div> -->
    </div>
</template>

<script>
// import Button from "@shared/components/button";
import ListView from "@shared/components/lists";
import axios from "@/axios";
import Loader from "@shared/loader";
import { getChecksList } from "@/modules/checks/services.js";
import { getAllClientPackages, getChecksAssignedtoClient } from "../services";

export default {
    name: "client-package-and-check",
    components: {
        // Button,
        ListView,
        Loader,
    },
    props: {},
    data() {
        return {
            loadingState: {
                packages: false,
                checks: false,
                assigningpackages: false,
                assigningchecks: false,
            },
            packages: [],
            checks: [],
            serchValues: {
                checks: "",
                packages: "",
            },
            clientPackages: [],
            clientChecks: [],
            clientId: null,
        };
    },
    computed: {
        filterdChecks() {
            return this.checks
                .map((e) => {
                    for (let i of this.clientChecks) {
                        if (i.id === e.id) {
                            return { ...e, checked: true };
                        }
                    }
                    return { ...e, checked: false };
                })
                ?.filter((el) => el.name?.toLowerCase()?.includes(this.serchValues.checks.toLowerCase()));
        },
        filterdPackages() {
            return this.packages
                .map((e) => {
                    for (let i of this.clientPackages) {
                        if (i.package_id === e.id) {
                            return { ...e, checked: true };
                        }
                    }
                    return { ...e, checked: false };
                })
                ?.filter((el) => el.package_name?.toLowerCase()?.includes(this.serchValues.packages.toLowerCase()));
            // return this.packages?.filter((el) => el.package_name?.toLowerCase()?.includes(this.serchValues.packages.toLowerCase()));
        },
    },
    async mounted() {
        this.loadingState.packages = true;
        this.loadingState.checks = true;
        this.clientId = this.$route.params.clientId;
        await this.getClientpackage();
        await this.getClientChecks();
        await this.fetchTenantPackages();
        await this.fetchChecksList();
    },
    methods: {
        async fetchTenantPackages() {
            // this.isLoading = true
            this.loadingState.packages = true;
            const { data } = await axios.get(`/screening-package`);
            this.packages = data;
            this.loadingState.packages = false;
        },
        async fetchChecksList() {
            this.loadingState.checks = true;
            this.checks = await getChecksList(this.$store.getters.getTenantId);
            this.loadingState.checks = false;
        },
        // selectAll(event, type){
        //     this[type] = this[type].map(el => ({...el, checked:event || false}))
        // },
        handleFilterResult(event, type) {
            this.serchValues[type] = event;
        },
        async handlePackageClick({ row, value }) {
            value ? await this.handleLinkPackages(row) : await this.removePackage(row);
        },
        async getClientpackage() {
            this.clientPackages = await getAllClientPackages(this.clientId);
        },
        async handleLinkPackages(packageData) {
            const url = `/screening-package/${packageData.id}/${this.clientId}`;
            this.loadingState.assigningpackages = true;
            try {
                let { data } = await axios.put(url);
                let package_data = {
                    ...packageData,
                    id: data.client_package_id,
                    package_id: packageData.id,
                };
                this.clientPackages.push(package_data);
                this.$toast.success("Package linked with client");
            } catch (error) {
                this.$toast.warning(error.response.data.detail || "Error while attaching package with client !");
            }
            this.loadingState.assigningpackages = false;
        },
        async removePackage(packageData) {
            this.loadingState.assigningpackages = true;
            const url = `/screening-package/${packageData.id}/${this.clientId}`;
            try {
                let { data } = await axios.delete(url);
                // this.clientPackages = this.clientPackages.filter((el) => el.package_id !== data.package_id);
                this.$toast.success(data.detail);
            } catch (error) {
                this.$toast.warning(error.response.data.detail || "Error while unassigning check from client !");
            }
            this.loadingState.assigningpackages = false;
        },
        async handleCheckClick({ row, value }) {
            value ? await this.handleLinkCheck(row) : await this.unassignChecksToClient(row);
        },
        async getClientChecks() {
            this.clientChecks = await getChecksAssignedtoClient(this.clientId);
        },
        async handleLinkCheck(checkData) {
            this.loadingState.assigningchecks = true;
            const url = `/tenant-check/${checkData.id}/assign-to-client`;
            let payload = {
                client_id: this.clientId,
            };
            try {
                let { data } = await axios.post(url, payload);
                this.clientChecks.push(checkData);
                this.$toast.success(data.message || "check assigned to client");
            } catch (error) {
                this.$toast.warning(error.response.data.detail || "Error while attaching package with client !");
            }

            this.loadingState.assigningchecks = false;
        },

        async unassignChecksToClient(check) {
            this.loadingState.assigningchecks = true;
            const url = `/tenant-check/${check.id}/unassign-from-client`;
            let payload = {
                client_id: this.clientId,
            };
            try {
                let { data } = await axios.post(url, payload);
                this.clientChecks = this.clientChecks.filter((el) => el.id !== check.id);
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.warning(error.response.data.detail || "Error while unassigning check from client !");
            }
            this.loadingState.assigningchecks = false;
        },
    },
};
</script>

<style lang="scss" scoped></style>
