import axios from "@/axios"

export async function getDomainTenantsList(params) {
    const url = `/delvium-admin/domain/tenants?${params}`
    return await axios.get(url)
}

export async function getEmailDomainTenantsList(params) {
    const url = `/delvium-admin/domain/email-domain/tenants?${params}`
    return await axios.get(url)
}