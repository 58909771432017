<template>
    <div>
        <div class="bg-white p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.address">
            <Loader />
        </div>
        <div v-else class="bg-white addressContainer">
            <div class="HeadingPart bg-primary-300">Address</div>
            <FormulateForm  #default="{ hasErrors }">
                <div class="flex flex-col gap-6 p-4">
                    <!-- Primary Contact -->
                    <div class="addressDetails">
                        <h1 class="text-gray-700 p-3 font-semibold text-lg mb-3 bg-primary-300 address">Primary Address</h1>
                        <div class="bg-white p-6 rounded-lg infoHeadings">
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Line 1" placeholder="Line 1" type="text" v-model="address.primary_address.line_1" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput class="w-full" label="Line 2" placeholder="Line 2" type="text" v-model="address.primary_address.line_2" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                            <div class="grid grid-cols-3 gap-6">
                                <FormulateInput class="w-full" label="City" placeholder="City" type="text" v-model="address.primary_address.city" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <div>
                                    <h2 class="mb-1 mt-1">Country</h2>
    
                                    <Dropdown
                                        placeholder="Country"
                                        :options="countries"
                                        searchable
                                        :colors="{
                                            text: '#282828',
                                            bg: 'white',
                                            svg: '#282828',
                                            border: '#bcbcbc',
                                        }"
                                        :value="address.primary_address.country"
                                        @input="(value) => updateCountry(value, 'primary_address')"
                                        :config="{ label: 'name', trackBy: 'name' }"
                                        maxWidth="100%"
                                        minWidth="100%"
                                        width="102px"
                                        height="20px"
                                        :loading="loadingState.country"
                                    />
                                </div>
    
                                <FormulateInput 
                                    v-model="address.primary_address.zip_code" 
                                    type="text" 
                                    label="Postcode" 
                                    placeholder="Postcode" 
                                    :validation="[address.primary_address.zip_code ? ['matches', /^[a-zA-Z0-9\s\-]+$/]: []]" 
                                    :validation-messages="{ matches: 'Only characters supported are alphanumeric, - and spaces' }" 
                                    class="w-full" 
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                                />
                            </div>
                        </div>
                        
                    </div>
                    <div class="form-control">
                        <label class="label cursor-pointer justify-start space-x-3">
                            <input type="checkbox" @click="primaryAddress" class="checkbox  checkbox-primary" v-model="address.primary_address_check"/>
                            <span class="label-text">Use Primary Address as Billing Address</span> 
                        </label>
                    </div>
                    <!-- Accounts Contact -->
                    <div class="addressDetails">
                        <h1 class="text-gray-700 p-3 font-semibold text-lg mb-3 address bg-primary-300">Billing Address</h1>
                        <div class="bg-white p-6 rounded-lg infoHeadings">
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Line 1" placeholder="Line 1" type="text" v-model="address.billing_address.line_1" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput class="w-full" label="Line 2" placeholder="Line 2" type="text" v-model="address.billing_address.line_2" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                            <div class="grid grid-cols-3 gap-6">
                                <FormulateInput class="w-full" label="City" placeholder="City" type="text" v-model="address.billing_address.city" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                
                                <div>
                                    <h2 class="mb-1 mt-1">Country</h2>
    
                                    <Dropdown
                                        placeholder="Country"
                                        :options="countries"
                                        searchable
                                        :colors="{
                                            text: '#282828',
                                            bg: 'white',
                                            svg: '#282828',
                                            border: '#bcbcbc',
                                        }"
                                        :value="address.billing_address.country"
                                        @input="(value) => updateCountry(value, 'billing_address')"
                                        :config="{ label: 'name', trackBy: 'name' }"
                                        maxWidth="100%"
                                        minWidth="100%"
                                        width="102px"
                                        height="20px"
                                        :loading="loadingState.country"
                                    />
                                </div>
    
                                <FormulateInput 
                                    v-model="address.billing_address.zip_code" 
                                    type="text" 
                                    label="Postcode" 
                                    placeholder="Postcode" 
                                    :validation="[address.billing_address.zip_code ? ['matches', /^[a-zA-Z0-9\s\-]+$/]: []]"
                                    :validation-messages="{ matches: 'Only characters supported are alphanumeric, - and spaces' }" 
                                    class="w-full" 
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex mt-4 justify-between w-full bg-white p-4 buttonPanel">
                    <Button text="Back" type="outline" @click="$emit('handleBackSection')" />
                    <Button :loader="loadingState.submit" :text="$route.params.tenant_id?'Update & Next':'Save & Next'" type="primary" @click="handleSubmit" :disabled="hasErrors"/>
                </div>
            </FormulateForm>
        </div>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import { getAddresses, getCountryList, getTenantAddress } from "../services";
import Loader from "@shared/loader";
import Dropdown from "@shared/components/dropdown-base";

export default {
    name: "client-admin-address",
    components: {
        Button,
        Loader,
        Dropdown,
    },
    props: {},
    data() {
        return {
            address: {
                primary_address: {
                    line_1: "",
                    line_2: "",
                    city: "",
                    country: "",
                    zip_code: false,
                },
                billing_address: {
                    line_1: "",
                    line_2: "",
                    city: "",
                    country: "",
                    zip_code: false,
                },
                temp:{
                    line_1: "",
                    line_2: "",
                    city: "",
                    country: "",
                    zip_code: false,
                },
                key: "address",
                primary_address_check: false
            },
            loadingState: {
                address: false,
                country: false,
                submit: false
            },
            clientId: null,
            countries: [],
        };
    },
    async mounted() {
        if(this.$route.params?.tenant_id) {
            this.loadingState.address = true
            const {data} = await getTenantAddress(this.$route.params.tenant_id)
            this.address.primary_address = data.address.primary_address ? data.address.primary_address : this.address.primary_address
            this.address.primary_address_check = data.address.primary_address_check;
            this.address.billing_address = data.address.billing_address ? data.address.billing_address : this.address.billing_address
            this.address["key"] = "address"
            this.$emit('enableAllSelection')
            this.loadingState.address = false
            this.address.temp = this.address.billing_address
        }
        await this.getClientAddress();
        await this.fetchCountry();
    },
    methods: {
        async fetchCountry() {
            this.loadingState.country = true;
            this.countries = await getCountryList();
            this.loadingState.country = false;
        },
        async getClientAddress() {
            this.loadingState.address = true;
            this.clientId = this.$route.params.clientId;
            if (this.clientId) {
                try {
                    let response = await getAddresses(this.clientId);
                    this.address = this.convertResponse(response);
                } catch (error) {
                    console.log(error);
                }
            }
            this.loadingState.address = false;
        },
        convertResponse(addresses) {
            if (addresses.length > 0) {
                let primary_address = addresses?.find((el) => el.address_type === "Primary Address") || '';
                let billing_address = addresses?.find((el) => el.address_type === "Billing Address") || '';
                let address = {
                    primary_address,
                    billing_address,
                };
                return address;
            } else {
                return this.address;
            }
        },
        async handleSubmit() {
            this.loadingState.submit = true
            this.$emit('handleNextSection', this.address)
            this.loadingState.submit = false
        },
        updateCountry(value, type) {
            this.address = {...this.address, [type] : {...this.address[type], country:value.name}}
        },
        primaryAddress(){
            if(!this.address.primary_address_check){
                this.address.billing_address = this.address.primary_address
            }
            else{
                this.address.billing_address = this.address.temp
            }

        }
    },
};
</script>

<style lang="scss" scoped>
    .dropdown{
        min-height: 40px !important;
    }

    .addressContainer {
        border-radius: 10px;
        // min-height: 100vh;
    }

    .HeadingPart {
        // background-color: #99CBFF;
        padding: 10px;
        border-radius: 10px 10px 0px 0px;
        color: #000;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    .infoHeadings {
        color: #282828;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
    }

    .addressDetails {
        border-radius: 12px;
        border: 1px solid #DDD;
    }

    .address {
        border-radius: 12px 12px 0px 0px;
        // background: #B4D6F9;
        color: #282828;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .buttonPanel {
        position: absolute;
        left: 0;
        // bottom: 0;
    }
</style>
